@charset "utf-8";

header {
  position: relative;
  z-index: 53;
  width: 100%;
  background: url(/wp-content/uploads/h_bg.jpg) no-repeat;
  background-size:100% 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  @if $main_slide_type == 'B' or $header_alpha == true {
    body.home.scrollTop & {
      box-shadow: none;
    }
  }

  section {
    margin: 0;
    width: 100%;
  }
  .row {
    margin: 0 3%;
  }

  // Primary
  .primary_header {
    color: $color_header_text;
  }

  // Logo
  .title {
    max-width: 40%;
    margin: 0;
    width:20%;
    padding: 10px 1%;
    line-height: 1;
    text-align: left;
    @include fontsize(10);
    img {
      width: auto;
      max-width: 100%;
      vertical-align: middle;
    }
    a {
      display: inline-block;
    }
    object {
      pointer-events: none;
      width: $logo_width+px;
      height: $logo_height+px;
      @media(max-width: 600px) {
        width: $logo_sp_width+px;
        height: ($logo_height/$logo_width)*$logo_sp_width+px;
        min-height: 45px;
      }
    }
  }

  // Global Navigation
  .global_nav {
    background: rgba(49, 25, 0, .7);
    &:not(:last-child) {
      display: none;
    }
    >ul {
      position: absolute;
      z-index: -1;
      top: 100%;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      @include listclear();
      border-top: 1px solid $color_gnav_border_sp;
      >li {
        border-bottom: 1px solid $color_gnav_border_sp;
        a {
          display: block;
          padding: 1.5em 1em;
          text-decoration: none;
          color: $color_navlink_sp;
          background: $color_gnav_bg;
          &:hover {
            color: $color_navlink_sp;
          }
          &:active {
            background: $color_navlink_sp_active_bg;
            color: $color_navlink_sp_active;
          }
        }
        >a {
          background: $color_gnav_bg;
        }
      }
    }
    // ハンバーガーメニュー
    .menu_icon {
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translateY(-50%);
      a {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        padding: 0;
        transition: .3s;
        overflow: hidden;
        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          width: 100%;
          height: 4px;
          background: $color_primary;
          transition: .5s;
        }
        &:before {
          top: 0;
        }
        &:after {
          bottom: 0;
        }
        span {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          left: 0;
          right: 0;
          margin: auto;
          width: 100%;
          height: 4px;
          background: $color_primary;
          transition: .5s;
          @include image-replace();
        }
        &.close:not(.fix) {
          background: none;
          &:before {
            top: 50%;
            transform: translate(0, -50%) rotate(45deg);
            background: $color_primary;
          }
          &:after {
            bottom: 50%;
            transform: translate(0, 50%) rotate(-45deg);
            background: $color_primary;
          }
          span {
            transform: translate(100%, -50%);
          }
        }
      }
    }
  }
  .global_nav {
    >ul {
      transform-origin: center top;
      visibility: hidden;
      transition: transform .3s;
      @if $navi_type_sp == 'x' {
        transform: translateX(100%);
      }@else{
        transform: rotateX(90deg);
      }
    }
  }
  &.menu_open {
    .global_nav {
      >ul {
        visibility: visible;
        @if $navi_type_sp == 'x' {
          transform: translateX(0);
        }@else{
          transform: rotateX(0deg);
        }
      }
    }
  }
}

// 固定ヘッダ
@if $fixed_header != false {
  body {
    header {
      transition: .5s;
    }
    &.scroll {
      &.down header {
        position: absolute;
        top: 0;
        left: 0;
        &.high {
          position: fixed;
        }
      }
      header {
        z-index: 0;
        &.hidden {
          top: -100%;
        }
      }
    }
  }
  header.menu_open,
  body.up header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
}

#google_translate_element, .translate_gt {
  position: absolute;
  top: 50%;
  right: 15%;
  margin-top: -12px;
}
.translate_gt {
  margin-top: -14px;
}

@media ( min-width : $header_break_point+px ) {

  body {
    @if $header_alpha != false {
      padding-top: 0!important;
    }
    &.scrollTop {

      &.home {

        header {
          @if $main_slide_type == 'B' or $header_alpha != false {
            background: transparent;
          }
        }
      }
    }
  }

  header {

    // Primary
    .primary_header {
    }

    .container {
     .row {
        display: table;
        width: 100%;
        margin-bottom: 0;
      }
    }
    .row {
      margin: 0;
      @if $header_logo_type != 'B' {
        display: table;
        margin: 0 3%;
        width: 94%;
        @if $header_logo_type == 'A' {
          table-layout: fixed;
        }
      }
      @if $header_logo_type != 'A' {
        height: $header_height+px;
      }
    }
    .title, .global_nav {
      @if $header_logo_type == 'A' {
        height: $header_height+px;
      }
      @if $header_logo_type != 'B' {
        display: table-cell;
      }
      margin: 0;
      vertical-align: middle;
    }
    .title {
      max-width: none;
      width: $logo_width+px;
      @if $header_logo_type == 'B' {
        width: 100%;
        text-align: center;
      }
      @if $header_logo_type == 'C' {
        text-align: center;
      }
      @if $header_logo_type != 'A' {
        object {
          max-width: 100%;
        }
      }
    }
    .global_nav {
      text-align: right;
      $val: $logo_width+px;
      width: calc(94vw - #{$val});
      padding: 12px 0;
      @if $header_logo_type == 'B' {
        width: 100%;
        text-align: center;
      }
      @if $header_logo_type == 'C' {
        $val: $logo_width+px;
        width: calc((100% - #{$val}) / 2);
        text-align: center;
      }
      @if $header_search == 'B' {
        vertical-align: bottom;
        padding-bottom: 1em;
      } @else if $header_search == 'C' {

      }
    }

    // Logo
    .title {
      max-width: none;
      padding: 43px 0 29px;
      img {
        width: auto;
      }
    }

    // Global Navigation
    .global_nav {
      &:not(:last-child) {
        display: table-cell;
      }
      &:last-child {
        >ul {
          >li {
            @if $header_logo_type == 'C' {
              &:nth-child(-n+3),
              &:nth-child(n+7) {
                display: none;
              }
            }
          }
        }
      }
      >ul {
        position: static;
        display: inline-block;
        width: auto;
        @if $header_logo_type == 'A' {
          padding-left: 20px;
        }
        border: none;
        background: none;
        visibility: visible;
        @include fontsize(0);
        transform: none;
        text-align: left;
        overflow: visible;
        >li {
          display: inline-block;
          vertical-align: middle;
          border: none;
          line-height: 1.5;
          @include fontsize($base_font_size);
          >a {
            display: inline;
            padding: 0;
            color: $color_navlink_pc;
            text-decoration: none;
            background: transparent;
            &:hover {
              border-bottom: 2px solid $color_primary;
              color: $color_navlink_pc;
            }
            &:active {
              background: none;
            }
          }
          padding: .3em 1.2em;
          border-left: 1px;
          &:last-child {
            border-right: 1px;
          }
          a {
            padding: .3em 0;
            transition: .3s;
          }
        }
      }
      .menu_icon {
        display: none;
      }
    }
  }

  #google_translate_element, .translate_gt {
    position: static;
    float: right;
    margin-top: 3px;
    margin-left: 1em;
  }
  .translate_gt {
    margin-top: 0;
  }

}

@media ( max-width : 767px ) {
  header .global_nav > ul > li > a {
    >br,
    >span {
      display: none;
    }
  }
}
