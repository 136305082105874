@charset "utf-8";

// 画像のくり抜き normal or circle
// circleの場合は画像も正方形である必要がある
$th01_bnr_type: 'normal';

.th01_bnr {
  .th01_bnr_wrap {
    &.span_3 {
      @media(min-width: 768px) and (max-width: 1000px) {
        width: 50%;
        &:nth-child(3) {
          clear: both;
        }
      }
    }
  }
  .gutters {
    .th01_bnr_wrap {
      &.span_3 {
        @media(min-width: 768px) and (max-width: 1000px) {
          width: 49%;
          &:nth-child(3) {
            clear: both;
            margin-left: 0;
          }
        }
      }
    }
  }
  .th01_bnr_wrap {
    position: relative;
    >*:not(.th01_bnr_content) {
      padding: 1em;
    }
    >h3 {
      text-align: center;
      border-bottom: 0;
      span {
        display: block;
      }
    }
    >p {
      line-height: 2;
    }
    &:hover {
      @if $design_concept == 'material' {
        box-shadow:
          15px -5px 30px -18px rgba(0, 0, 0, 0.3),
          -15px -5px 30px -18px rgba(0, 0, 0, 0.3);
      }
    }
    &:hover {
      .th01_bnr_content {
        .th01_bnr_button {
          transform: translate(-50%, -50% );
          opacity: 1;
        }
        &:before {
          background: rgba($color_primary, .8);
          transform: rotateZ(45deg) scale(2, 3) translate(0, 0);
        }
      }
    }
  }
  .th01_bnr_content {
    position: relative;
    overflow: hidden;
    z-index: 2;
    @if $th01_bnr_type == 'circle' {
      border-radius: 50%;
    }
    >img {
      width: 100%;
    }
    .th01_bnr_button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0% );
      margin: auto;
      z-index: 1;
      opacity: 0;
      text-align: center;
      transition: .3s;
      a {
        $arrow_color: #fff;
        display: inline-block;
        letter-spacing: normal;
        border: 1px solid $arrow_color;
        color: $arrow_color;
        font-family: 'Arial Narrow', arial;
        text-decoration: none;
        @include fontsize(16);
        padding: .3em 1em;
        @media (min-width: 768px) {
          @include fontsize(24);
        }
        &:after {
          border-color: $arrow_color;
        }
      }
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
      background: rgba($color_primary, .3);
      transform: rotateZ(45deg) scale(2, 3) translate(75%, 0);
      transition: .3s;
    }
  }
}
