@charset "utf-8";

/* お問い合わせフォーム */
.inquiry {
  fieldset {
    margin: 0;
    padding: 20px 10px;
    border: 0;
    background: $color_inquiry_bg;
    .row {
      margin: 0;
    }
  }
  dl {
    margin: 0;
    padding: 0;
  }
  dt {
    font-weight: normal;
    &.col:not(:last-child) {
      margin-bottom: 1em;
    }
  }
  dd {
    margin: 0;
    padding: 0;
    &:last-child {
      padding-bottom: 0;
    }
    .confirm {
      padding: 0 0 1em 1em;
      border-bottom: 1px dashed #ccc;
    }
  }
  input[type="text"],
  input[type="url"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  input[type="number"],
  select,
  textarea {
    width: 100%;
    padding: 5px;
    color: #000;
    @include fontsize(16);
  }
  input[type="number"] {
    width: 65px;
    text-align: center;
  }
  input.p-postal-code {
    width: 100px;
  }
  input.p-region {
    width: 100px;
  }
  input.p-locality {
    width: 150px;
  }
  input.p-street-address {
    width: 100%;
  }
  input.p-extended-address {
    width: 100%;
  }
  .submit {
    margin: 1em 0;
    text-align: center;
  }
  .required {
    color: #c00;
  }
  label {
    display: block;
    margin-bottom: 0.5em;
  }
}


@media ( min-width : 768px ) {

  .inquiry {
    max-width: 780px;
    margin-right: auto;
    margin-left: auto;
    fieldset {
      margin: 0;
      padding: 60px;
      border: 0;
    }
    dt {
      clear: both;
      padding: 6px 0;
    }
    dd {
      padding-bottom: 1.5em;
      .confirm {
        min-height: 1.5em;
        padding: 6px 0 0;
        border: 0;
        line-height: 1.4;
      }
    }
    input[type="text"],
    input[type="url"],
    input[type="email"],
    input[type="tel"],
    input[type="date"],
    input[type="text"],
    select {
      width: 60%;
    }
    input[type="number"] {
      width: 65px;
    }
    input.p-postal-code {
      width: 100px;
    }
    input.p-region {
      width: 100px;
    }
    input.p-locality {
      width: 150px;
    }
    input.p-street-address {
      width: 100%;
    }
    input.p-extended-address {
      width: 100%;
    }
    textarea {
      width: 100%;
    }
    .submit {
      margin: 60px 0 0;
    }
    label {
      display: inline-block;
      margin-top: 6px;
      margin-bottom: 0;
      margin-right: 0.8em;
    }
  }

  .gutters .inquiry dt.col {
    margin-left: 0;
  }

}