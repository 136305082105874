@charset "utf-8";

// 以下の画像サイズ設定は画像の比率決定のために使用します。
$th02_post03_width: 360; //画像のwidth
$th02_post03_height: 200; //画像のheight

.th02_post03 {
  .col {
    position: relative;
    padding-bottom: 23px;
    box-sizing: content-box;
    overflow: hidden;
    .th02_post03_image {
      position: relative;
      padding-top: ($th02_post03_height / $th02_post03_width)*100 + %;
      overflow: hidden;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: $color_primary;
        opacity: .7;
        width: 35%;
        height: 100%;
        transform: translateX(-100%) skew(-25deg);
        transform-origin: bottom;
        @media(max-width: 600px) {
          width: 50%;
          transform: translateX(-50%) skew(-25deg);
        }
        z-index: 1;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .th02_post03_date {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      padding: .7em;
      color: #fff;
      z-index: 1;
    }
    .th02_post03_text {
      position: relative;
      padding: 1em 1em 2.5em;
    }
    .th02_post03_button {
      position: absolute;
      left: 1em;
      bottom: 1em;
    }
  }
}

@media ( max-width : 767px ) {
  $th02_post03_width_sp: 40; // 画像カラムの比率
  .th02_post03 {
    .col {
      .th02_post03_image {
        width: $th02_post03_width_sp+%;
        padding-top: ($th02_post03_height / $th02_post03_width)*$th02_post03_width_sp + %;
        float: left;
        margin: 0 2% 2% 0;
      }
      .th02_post03_button {
        position: static;
      }
    }
  }
}
