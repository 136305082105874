@charset "utf-8";

// main_visual
.main_visual {
  position: relative;
  margin-bottom: 0;
  .bx-wrapper {
    position: relative;
  }
  .bx-viewport {
    height: 100%!important;
  }
  .main_visual_slide {
    @include listclear();
    position: relative;
    li {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 100%;
      img {
        @include position-center-wide();
        top: 0;
        height: 100%;
      }
    }
  }
  .main_visual_copy {
    @include position-center();
    @include listclear();
    width: $main_slide_width+px;
    max-width: 100%;
    margin: 0 auto;
    z-index: 53;
    @if $main_slide_copy_sync != false {
      transform: scale(1);
    }
    >li {
      @include position-center();
      @if $main_slide_copy_sync != false {
        display: none;
      }
      img {
        @include position-center();
        width: ($main_slide_copy_width / $container)*100+%;
        max-width: $main_slide_copy_width+px;
        max-height: $main_slide_copy_height+px;
        z-index: 52;
      }
    }
  }
}
@media ( max-width : $main_slide_break_point+px ) {
  .main_visual {
    .main_visual_copy {
      >li {
        img {
          width: 70%;
        }
      }
    }
  }
}

@if $main_slide_type == 'A' {
  .main_visual {
    .main_visual_slide {
      padding-top: ($main_slide_height / $main_slide_width)*100 + %;
    }
  }
  @media ( max-width : 1000px ) {
    .main_visual {
      .main_visual_slide {
        padding-top: (($main_slide_height / $main_slide_width)*100)*1.5 + %;
      }
    }
  }
  @media ( max-width : 768px ) {
    .main_visual {
      .main_visual_copy {
        >li {
          img {
            width: 43%;
          }
        }
      }
    }
  }
  @media ( max-width : 600px ) {
    .main_visual {
      .main_visual_copy {
        >li {
          img {
            width: 90%;
          }
        }
      }
    }
  }
  @media ( max-width : $main_slide_break_point+px ) {
    .main_visual {
      .main_visual_slide {
        padding-top: (($main_slide_height / $main_slide_width)*100)*$main_slide_correct + %;
      }
    }
  }
}

@if $main_slide_type == 'B' {
  body.home {
    padding-top: 0!important;
  }
  header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 52;
  }
  .main_visual {
    .main_visual_slide {
      padding-top: 100%;
      padding-top: 100vh;
    }
  }
  @media ( min-aspect-ratio : $main_slide_width+'/'+$main_slide_height ) {
    .main_visual {
      .main_visual_slide {
        li {
          img {
            width: 100%;
            height: auto!important;
          }
        }
      }
    }
  }
}

@if $main_slide_type == 'C' {
  .main_visual {
    .main_visual_slide {
      padding-top: ($main_slide_height / $container)*100 + %;
    }
  }
  @media (min-width: $container+px) {
    .main_visual {
      .main_visual_slide {
        padding-top: 0;
        height: $main_slide_height+px;
      }
    }
  }
  @media (min-width: $main_slide_width+px) {
    .main_visual {
      .main_visual_slide {
        padding-top: ($main_slide_height / $main_slide_width)*100 + %;
      }
    }
  }
  @media ( max-width : $main_slide_break_point+px ) {
    .main_visual {
      .main_visual_slide {
        padding-top: (($main_slide_height / $container)*100)*$main_slide_correct + %;
      }
    }
  }
}

@if $main_slide_type == 'D' {

  $main_slide_type_main: 80; // %
  $main_slide_type_thumbnail: (100-$main_slide_type_main); // %
  $main_slide_type_result: ($main_slide_height / $main_slide_width)*400/$main_slide_type_count;

  // main_visual
  .main_visual {
    position: relative;
    overflow: hidden;
    transform: scale(1);
    .bx-wrapper {
    }
    .bx-viewport {
      height: 100%!important;
    }
    .main_visual_slide {
      @include listclear();
      position: relative;
      padding-top: ($main_slide_height / $main_slide_width)*100 + %;
      li {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 100%;
        img {
          @include position-center-wide();
          top: 0;
          height: 100%;
        }
      }
    }
    .main_visual_slide_thumb {
      @include listclear();
      letter-spacing: -.40em;
      >li {
        letter-spacing: normal;
        vertical-align: top;
        display: inline-block;
        width: 100/$main_slide_type_count+%;
        position: relative;
        overflow: hidden;
        padding-top: ($main_slide_height / $main_slide_width)*100/$main_slide_type_count + %;
        cursor: pointer;
        user-select: none;
        img {
          height: 100%;
          position: absolute;
          top: 0;
          left: -300%;
          right: -300%;
          margin: auto;
          @if $main_slide_type_count > 4 {
            height: auto;
            width: 100%;
          }
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background: #000;
          opacity: .3;
          width: 100%;
          height: 100%;
          z-index: 1;
          transition: .3s;
        }
        &.main_visual_slide_thumb_current {
          &:after {
            opacity: 0;
          }
        }
      }
    }
  }
  @media ( max-width : $main_slide_break_point+px ) {
    .main_visual {
      .main_visual_slide {
        padding-top: (($main_slide_height / $main_slide_width)*100)*$main_slide_correct + %;
      }
      .main_visual_copy {
        >li {
          img {
            width: 70%;
          }
        }
      }
    }
  }
  @media (min-width: $header_break_point+px) {
    .main_visual {
      >* {
        @if $main_slide_type_thumbnail_position == 'left' {
          float: right;
        } @else {
          float: left;
        }
      }
      .bx-wrapper {
        width: $main_slide_type_main+%;
      }
      .bx-viewport {
      }
      .main_visual_slide {
        li {

          img {

          }
        }
      }
      .main_visual_slide_thumb {
        width: $main_slide_type_thumbnail+%;
        >li {
          display: block;
          width: 100%;
          padding-top: $main_slide_type_result+%;
          img {

          }
        }
      }
    }

  }
}

.slide_scroll {
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 45px;
  background: url(https://www.sss-3s.co.jp/wp-content/uploads/icon_scroll.png) center bottom no-repeat;
  z-index: 55;
  color: #fff;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
}
