@charset "utf-8";

$th02_bnr_type: 'container'; // コンテナwidth container or wide

$th02_bnr_background: rgba(49, 25, 0, .9);
$th02_bnr_color: #fff;
$th02_bnr_more_color: #35cce9;

$th02_bnr_hover_effect: 'border'; //'none' or 'border'
$th02_bnr_line_distance: 5;
$th02_bnr_line_weight: 2;
$th02_bnr_line_color: #fff;

.th02_bnr {
  .container {
    @if $th02_bnr_type == 'container' {
      max-width: $container+px;
    } @else {
      max-width: 100%;
    }
  }
  img {
    max-width: 100%;
  }
  .th02_bnr_text {
    padding: 1.5em;
    background: $th02_bnr_background;
    color: $th02_bnr_color;
    h3 {
      color: $th02_bnr_color;
      &:before,
      &:after {
        background: $th02_bnr_color;
      }
    }
    .th02_bnr_button {
      &.more {
        a {
          position: relative;
          color: $th02_bnr_more_color;
          border: 1px solid $th02_bnr_more_color;
          font-family: arial;
          &:after {
            border-color: $th02_bnr_more_color;
          }
        }
      }
    }
  }
  .th02_bnr_text_inner {
    overflow: hidden;
    display: inline;
  }
}

@media (min-width: 768px) {
  .th02_bnr {
    .tile {
      >.col {
        position: relative;
        @if $th02_bnr_hover_effect == 'border' {
          &:before,
          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 0;
            height: $th02_bnr_line_weight+px;
            background: $th02_bnr_line_color;
            z-index: 1;
            transition: .5s;
          }
          &:before {
            top: $th02_bnr_line_distance+px;
            left: $th02_bnr_line_distance+px;
          }
          &:after {
            bottom: $th02_bnr_line_distance+px;
            right: $th02_bnr_line_distance+px;
          }
          &:hover {
            &:before,
            &:after {
              $val: ($th02_bnr_line_distance * 2)+px;
              width: calc(100% - #{$val});
            }
          }
        }
        .row {
          display: flex;
          height: 100%;
          width: 100%;
          @if $th02_bnr_hover_effect == 'border' {
            position: relative;
            &:before,
            &:after {
              content: '';
              display: block;
              position: absolute;
              $val: ($th02_bnr_line_distance * 2)+px;
              height: 0;
              width: $th02_bnr_line_weight+px;
              background: $th02_bnr_line_color;
              z-index: 1;
              transition: .5s;
            }
            &:before {
              left: $th02_bnr_line_distance+px;
              bottom: $th02_bnr_line_distance+px;
            }
            &:after {
              right: $th02_bnr_line_distance+px;
              top: $th02_bnr_line_distance+px;
            }
            &:hover {
              &:before,
              &:after {
                $val: ($th02_bnr_line_distance * 2)+px;
                height: calc(100% - #{$val});
              }
            }
          }
        // &:nth-child(1),
        // &:nth-child(2),
        // &:nth-child(5),
        // &:nth-child(6) {
        //   .row {
        //     flex-direction : row-reverse;
        //   }
        // }
          &.reverse {
            flex-direction : row-reverse;
          }
        }
      }
    }
    .th02_bnr_text {
      position: relative;
      transition: .3s;
      &:hover {
        @if $design_concept == 'material' {
          box-shadow:
            15px -5px 30px -18px rgba(0, 0, 0, 0.3),
            -15px -5px 30px -18px rgba(0, 0, 0, 0.3);
        }
      }
      p:not(.th02_bnr_button) {
        margin-bottom: 4em;
        line-height: 2;
      }
      .th02_bnr_button {
        position: absolute;
        bottom: 1.5em;
        left: 1.5em;
      }
    }
    .th02_bnr_img {
      position: relative;
      overflow: hidden;
      height: 100%;
      img {
        position: absolute;
        top: -100%;
        bottom: -100%;
        left: -100%;
        right: -100%;
        margin: auto;
        height: 100%;
        max-width: none;
      }
    }
  }
}

@media (min-width: 1000px) {
  .th02_bnr {
    .tile {
      >.col {
        @if $th02_bnr_type == 'container' {
          $val: $container+px;
          min-height: calc(#{$val} / 4)!important;
        } @else {
          min-height: 25vw!important;
        }
        // height: 0!important;
        // padding-top: 25%;
        position: relative;
        .row {
          // position: absolute;
          // top: 0;
          // left: 0;
        }
      }
    }
  }
}
