@charset "utf-8";

@import "color";

// ======== fonts
  $base_font_size: 14; // px
  // ヒラギノ明朝
  $HiraMin: Verdana, Roboto, "Droid Sans", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  // ヒラギノ角ゴ
  $Hiragino: Verdana, Roboto, "Droid Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  // メイリオ
  $Meiryo: Verdana, Roboto, "Droid Sans", "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif;

  $base_font_family: $Hiragino;

// ======== header
  // 共通
  $design_concept: 'material'; // デザインコンセプト material or flat
  $container: 1200; // containerの最大幅
  $header_break_point: 769; // ヘッダーのブレイクポイント（ロゴ/Gナビの調整が難しい場合1000px等に設定する）
  $fixed_header: true; // 固定ヘッダー true or false
  $header_type: 'top'; // メインビジュアルに対するヘッダーの表示位置 'top' or 'bottom'
  $navi_type_sp: 'y'; // スマホヘッダナビの表示軸　'x' or 'y'　気分で

  // svg形式のロゴのサイズをラフを元に正確に入れる
  // svg内部の数値とCSS上で扱う数値は違うので要注意
  $logo_width: 364; // ロゴの横幅
  $logo_height: 65; // ロゴの縦幅
  $logo_sp_width: 250; // スマホ時のロゴ横幅　（横320に対して、ハンバーガーメニューを加えても余裕のある値にしておく）

  $header_height: 200; // ヘッダー全体の高さ
  $header_logo_type: 'B'; // ヘッダのロゴタイプ　'A' or 'B' or 'C'

  $header_alpha: false; // ヘッダの高さを無くし透過させるか　true or false

  // ヘッダの検索表示タイプ
  // 'A'・・・アイコンによる開閉式
  // 'B'・・・テキストボックスが”右上”に常に見えているタイプ
  // 'C'・・・テキストボックスが”Gナビと同じ並びで”常に見えているタイプ
  $header_search: 'A';

  // ヘッダの検索デザインタイプ
  // 'A'・・・シンプルな検索ボックス
  // 'B'・・・アイコンタイプ（左）
  // 'C'・・・アイコンタイプ（右）
  $header_search_design: 'A';

  $header_menu_type: 'A'; // メニュータイプ　'A' or 'B' or 'C'

  $footer_type: 'B'; // フッタのデザインタイプ　'A' or 'B'
  $pagetop_type: 'A'; // ページトップのデザインタイプ　'A' or 'B' or 'C'
  $side_column: 'right'; // 下層カラムの位置　'left' or 'right'

  // メインビジュアル
  $main_slide_width: 1920; //スライド画像のwidth
  $main_slide_height: 610; //スライド画像のheight

  $main_slide_correct: 2; // スマホの際、高さが縮まりやすいので補正を入れる（倍率）
  $main_slide_break_point: 600; // 補正を入れるブレイクポイントを指定

  // スライド画像の見せ方
  // 'A'・・・常に画像全体が見えているスライド。　縦横比率を維持しながらリサイズされる
  // 'B'・・・全画面スライド。常にウィンドウの高さ＝メインビジュアルの高さとなる。
  // 'C'・・・ブレイクポイント～メイン画像の最大サイズまでは高さが最大サイズで固定となるスライド。ラフの時点でメイン画像の高さが絞られているケースに適している。
  // 'D'・・・サムネイル画像付きスライド。スライド枚数の入力が必要。オプションは３行先を参照。
  $main_slide_type: 'C';

  // スライドD用の表示オプション
  $main_slide_type_count: 4; //枚数
  $main_slide_type_thumbnail_position: 'right'; //サムネイル位置

  // スライド上のコピー画像
  // スライドに応じてFadeするか true or false
  // trueの場合、　.main_visual_slide li　と　.main_visual_copy li の数は
  // 一致している必要がある
  $main_slide_copy_sync: true; // スライドに応じてFadeするか true or false

  $main_slide_copy_width: 1152; //スライド上のコピー画像のwidth
  $main_slide_copy_height: 610; //スライド上のコピー画像のheight



// ======== button
  // 共通
  $button_minwidth: 0px; // 最低幅
  $button_arrow: 'left'; // <span>で囲ったときの矢印の位置 'left' or 'right'

  // デフォルトボタン
  $style_button_border: 0; // ボーダースタイル
  $style_button_border_radius: $style_button_border_radius; // 角丸

  // カラー反転ボタン
  $style_button_inverse_border: 0; // ボーダースタイル
  $style_button_inverse_border_radius: $style_button_border_radius; // 角丸

// ======== slider
  $slide_type: 'A' !default; // スライドのタイプ

  // Type A
  $shape_slider_arrow: 'square' !default; // 前後切り替えボタンの形状 'square' or 'circle'

  // Type B

  // コンテンツスライダー
  $contents_slider_image_width: 450px; // 画像の幅
  $contents_slider_image_height: 300px; // 画像の高さ
  $contents_slider_caption_minheight: 40px; // キャプションの高さの最小値
  $style_contents_slider_caption_border: 1px dashed #999; // キャプションの左右のボーダースタイル
  $contents_slider_arrow_width: 10px; // 前後切り替えボタンの幅

// ======== information
  $style_news_border: 1px solid #ccc; // 各お知らせ間のボーダースタイル

// ======== parallax
  $parallax_height_pc:(); $parallax_height_sp:(); $parallax_img:(); // 触らない

  // 以下3行を設置個数分コピーして編集
  // $parallax_height_pc: append($parallax_height_pc, 500px); // パララックスの高さ(PC)
  // $parallax_height_sp: append($parallax_height_sp, 250px); // パララックスの高さ(SP)
  // $parallax_img: append($parallax_img, url(../img/parallax_bg.jpg)); // パララックス画像のパス


// 設定ここまで
// ========================================================================================

@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/mixin/_base.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/mixin/_flexbox.scss";


/* version */

@import "vendor/common.css";

@import "common";
@import "header";
@import "footer";

@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/slider/_contents.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/slider/_typeA.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/slider/_typeB.scss";

@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_before_after.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_bread.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_button.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_feature.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_grid.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_guide.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_inquiry.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_movie.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_news.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_parallax.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_question_answer.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_shopinfo.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_sns.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_sp_image_cols.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_step.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_table.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/parts/_wide_image.scss";

@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/core/_calendar.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/core/_headline01.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/core/_main_slide.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/core/_menu.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/core/_pagetop.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/core/_search.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/core/_th01_bnr.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/core/_th02_bnr.scss";

@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/post/_th02_post02.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/post/_th02_post03.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/post/_th03_post01.scss";

@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/other/_effect.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/other/_th02_archive.scss";
@import "C:/xampp/htdocs/projects/src/base_x1532662.epressd.jp/assets/sass/other/_th02_single.scss";


@import "utility";

@import "custom";
