@charset "utf-8";

.news {
  // padding: 4% 0;
  .news_bg {background: $color_news_bg;}
  &.typeA {
    > div {display: table; width: 100%; margin: 0 auto; background-color: #fff; padding: 1em 1em 1.5em;
      .heading {display: table-cell; width: 23.5%; vertical-align: middle;}
    }
  }
  &.typeC {
    .news_bg {padding: 1em;}
  }
  h2 {
    display: block;
    margin: 0 auto 0;
    position: relative;
    word-wrap: break-word;
    font-weight: normal;
    text-align: center;
    @include fontsize(22);
    color: $color_h2_text;
    border-bottom: $color_primary double 4px;
    padding-bottom: .4em;
    &:before, &:after {display: none;}
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  dl {margin: 0; background-color: #fff; padding: 1em 1em 1.5em;
    dt {margin: 0.5em 0 0; padding: 0 1em; font-weight: normal;}
    dd {margin: 0.5em 0; padding: 0 1em 1em;
      a {text-decoration: none;
        &:hover {text-decoration: underline;}
      }
      &:not(:last-child){
        border-bottom: #e5e4e0 solid 1px;
      }
    }
  }
}

@media ( max-width : 768px ) {
  .news {
    &.typeA {
      > div {display: table; width: 100%; margin: 0 auto; background-color: #fff; padding: 1em 1em 1.5em;
        .heading {display: table-cell; width: 23.5%; vertical-align: middle;}
      }
      h2 {margin: 0; padding: 0 0 .5em; @include fontsize(19);
        &:after {display: none;}
      }
      .content {display: table-cell; overflow: hidden; width: 74.5%; padding-left: 2%; vertical-align: middle;}
    }
    &.typeC {
      .news_bg {height: 540px; padding: 20px;}
      dl {max-height: 500px;}
    }
    h2{
      @include fontsize(19); margin: 0; padding: 0 0 .5em; max-width: 100%!important; width: 100%;
    }
    dl {overflow-y: auto; max-height: 200px; background: #fff;
      dt {width: 100px; clear: left; float: left; margin: 0; padding: 1em 1em .5em;}
      dd {margin: 0; padding:  1em 1em .5em;}
      dt + dd {padding-left: 120px;}
    }
  }
}
