@charset "utf-8";

// ======== All Device
.custom {

}

// ======== Mobile
@media ( max-width : 480px ) {

} // @media

// ======== Tablet & PC
@media ( min-width : 768px ) {

} // @media

// ======== PC
@media ( min-width : 1024px ) {

} // @media


.t_center{text-align: center;}
.t_left{text-align: left;}
.t_right{text-align: right;}

.mt0{margin-top:0px !important;}
.mt5{margin-top:5px !important;}
.mt10{margin-top:10px !important;}
.mt15{margin-top:15px !important;}
.mt20{margin-top:20px !important;}
.mt25{margin-top:25px !important;}
.mt30{margin-top:30px !important;}
.mt35{margin-top:35px !important;}
.mt40{margin-top:40px !important;}
.mt45{margin-top:45px !important;}
.mt50{margin-top:50px !important;}
.mt55{margin-top:55px !important;}
.mt60{margin-top:60px !important;}
.mt65{margin-top:65px !important;}
.mt70{margin-top:70px !important;}
.mt75{margin-top:75px !important;}
.mt80{margin-top:80px !important;}
.mt85{margin-top:85px !important;}
.mt90{margin-top:90px !important;}
.mt95{margin-top:95px !important;}
.mt100{margin-top:100px !important;}

.mr0{margin-right:0px !important;}
.mr5{margin-right:5px !important;}
.mr10{margin-right:10px !important;}
.mr15{margin-right:15px !important;}
.mr20{margin-right:20px !important;}
.mr25{margin-right:25px !important;}
.mr30{margin-right:30px !important;}
.mr35{margin-right:35px !important;}
.mr40{margin-right:40px !important;}
.mr45{margin-right:45px !important;}
.mr50{margin-right:50px !important;}
.mr55{margin-right:55px !important;}
.mr60{margin-right:60px !important;}
.mr65{margin-right:65px !important;}
.mr70{margin-right:70px !important;}
.mr75{margin-right:75px !important;}
.mr80{margin-right:80px !important;}
.mr85{margin-right:85px !important;}
.mr90{margin-right:90px !important;}
.mr95{margin-right:95px !important;}
.mr100{margin-right:100px !important;}

.mb0{margin-bottom:0px !important;}
.mb5{margin-bottom:5px !important;}
.mb10{margin-bottom:10px !important;}
.mb15{margin-bottom:15px !important;}
.mb20{margin-bottom:20px !important;}
.mb25{margin-bottom:25px !important;}
.mb30{margin-bottom:30px !important;}
.mb35{margin-bottom:35px !important;}
.mb40{margin-bottom:40px !important;}
.mb45{margin-bottom:45px !important;}
.mb50{margin-bottom:50px !important;}
.mb55{margin-bottom:55px !important;}
.mb60{margin-bottom:60px !important;}
.mb65{margin-bottom:65px !important;}
.mb70{margin-bottom:70px !important;}
.mb75{margin-bottom:75px !important;}
.mb80{margin-bottom:80px !important;}
.mb85{margin-bottom:85px !important;}
.mb90{margin-bottom:90px !important;}
.mb95{margin-bottom:95px !important;}
.mb100{margin-bottom:100px !important;}

.ml0{margin-left:0px !important;}
.ml5{margin-left:5px !important;}
.ml10{margin-left:10px !important;}
.ml15{margin-left:15px !important;}
.ml20{margin-left:20px !important;}
.ml25{margin-left:25px !important;}
.ml30{margin-left:30px !important;}
.ml35{margin-left:35px !important;}
.ml40{margin-left:40px !important;}
.ml45{margin-left:45px !important;}
.ml50{margin-left:50px !important;}
.ml55{margin-left:55px !important;}
.ml60{margin-left:60px !important;}
.ml65{margin-left:65px !important;}
.ml70{margin-left:70px !important;}
.ml75{margin-left:75px !important;}
.ml80{margin-left:80px !important;}
.ml85{margin-left:85px !important;}
.ml90{margin-left:90px !important;}
.ml95{margin-left:95px !important;}
.ml100{margin-left:100px !important;}

.pt0{padding-top:0px !important;}
.pt5{padding-top:5px !important;}
.pt10{padding-top:10px !important;}
.pt15{padding-top:15px !important;}
.pt20{padding-top:20px !important;}
.pt25{padding-top:25px !important;}
.pt30{padding-top:30px !important;}
.pt35{padding-top:35px !important;}
.pt40{padding-top:40px !important;}
.pt45{padding-top:45px !important;}
.pt50{padding-top:50px !important;}
.pt55{padding-top:55px !important;}
.pt60{padding-top:60px !important;}
.pt65{padding-top:65px !important;}
.pt70{padding-top:70px !important;}
.pt75{padding-top:75px !important;}
.pt80{padding-top:80px !important;}
.pt85{padding-top:85px !important;}
.pt90{padding-top:90px !important;}
.pt95{padding-top:95px !important;}
.pt100{padding-top:100px !important;}

.pr0{padding-right:0px !important;}
.pr5{padding-right:5px !important;}
.pr10{padding-right:10px !important;}
.pr15{padding-right:15px !important;}
.pr20{padding-right:20px !important;}
.pr25{padding-right:25px !important;}
.pr30{padding-right:30px !important;}
.pr35{padding-right:35px !important;}
.pr40{padding-right:40px !important;}
.pr45{padding-right:45px !important;}
.pr50{padding-right:50px !important;}
.pr55{padding-right:55px !important;}
.pr60{padding-right:60px !important;}
.pr65{padding-right:65px !important;}
.pr70{padding-right:70px !important;}
.pr75{padding-right:75px !important;}
.pr80{padding-right:80px !important;}
.pr85{padding-right:85px !important;}
.pr90{padding-right:90px !important;}
.pr95{padding-right:95px !important;}
.pr100{padding-right:100px !important;}

.pb0{padding-bottom:0px !important;}
.pb5{padding-bottom:5px !important;}
.pb10{padding-bottom:10px !important;}
.pb15{padding-bottom:15px !important;}
.pb20{padding-bottom:20px !important;}
.pb25{padding-bottom:25px !important;}
.pb30{padding-bottom:30px !important;}
.pb35{padding-bottom:35px !important;}
.pb40{padding-bottom:40px !important;}
.pb45{padding-bottom:45px !important;}
.pb50{padding-bottom:50px !important;}
.pb55{padding-bottom:55px !important;}
.pb60{padding-bottom:60px !important;}
.pb65{padding-bottom:65px !important;}
.pb70{padding-bottom:70px !important;}
.pb75{padding-bottom:75px !important;}
.pb80{padding-bottom:80px !important;}
.pb85{padding-bottom:85px !important;}
.pb90{padding-bottom:90px !important;}
.pb95{padding-bottom:95px !important;}
.pb100{padding-bottom:100px !important;}

.pl0{padding-left:0px !important;}
.pl5{padding-left:5px !important;}
.pl10{padding-left:10px !important;}
.pl15{padding-left:15px !important;}
.pl20{padding-left:20px !important;}
.pl25{padding-left:25px !important;}
.pl30{padding-left:30px !important;}
.pl35{padding-left:35px !important;}
.pl40{padding-left:40px !important;}
.pl45{padding-left:45px !important;}
.pl50{padding-left:50px !important;}
.pl55{padding-left:55px !important;}
.pl60{padding-left:60px !important;}
.pl65{padding-left:65px !important;}
.pl70{padding-left:70px !important;}
.pl75{padding-left:75px !important;}
.pl80{padding-left:80px !important;}
.pl85{padding-left:85px !important;}
.pl90{padding-left:90px !important;}
.pl95{padding-left:95px !important;}
.pl100{padding-left:100px !important;}

/* -- color -- */
.t_red { color: #FF0000 !important;}
.t_blu { color: #285E9D !important;}
.t_grn { color: #357704 !important;}
.t_ylw { color: #ff9900 !important;}
.t_org { color: #FA804B !important;}
.t_ppl { color: #933366 !important;}
.t_pnk { color: #f84d70 !important;}
.t_brw { color: #522c04 !important;}
.t_wht { color: #fff !important;}
.t_blk { color: #000 !important;}

ul.note {margin-left: 5px !important; padding: 0 !important;}
ul.note li {margin: 0 0 5px 1.2em !important; padding: 0 !important; list-style: none !important;}
ul.note li span {margin: 0 0.5em 0 -1.2em !important;}

p.note {margin: 0 0 5px 1.2em !important; padding: 0 !important; list-style: none !important;}
p.note span {margin: 0 0.5em 0 -1.2em !important;}

section.th_01a{
	padding: 5em 0; background: url(/wp-content/uploads/bg_img.jpg);
	@media ( max-width : 768px ) {
		padding: 3em 0;
	}
	.container{
		@media ( max-width : 768px ) {padding: 0;}
		.row{
			margin-bottom: 0;
			@media ( max-width : 480px ) {
				margin-bottom: 3%;
			}
			.col{
				&.box_1{background: url(/wp-content/uploads/top_img001.jpg) no-repeat;}
				&.box_2{background: url(/wp-content/uploads/top_img002.jpg) no-repeat;}
				&.box_3{background: url(/wp-content/uploads/top_img003.jpg) no-repeat;}
				&.box_4{background: url(/wp-content/uploads/top_img004.jpg) no-repeat;
					.box_text{
						h3{
							margin-bottom: 20px;
							@media ( max-width : 768px ) {
								margin-bottom: 0;
							}
						}
						p{
							@media ( max-width : 768px ) {
								margin-top: 10px;
							}
						}
					}
				}
				&.box_1,
				&.box_2,
				&.box_3,
				&.box_4{
					background-size: 100% 100%;
					@media ( max-width : 768px ) {
						background-size: 100% auto;
					}
					@media ( max-width : 480px ) {
						padding-top:30%;
					}
					.box_text{
						margin-left: 50%;
						@media ( max-width : 768px ) {
							margin-left: 0;
						}
					}
				}
				&.box_3 .box_text,
				&.box_4 .box_text{
					margin-left: 0;
				}
				.box_text{
					position: relative; box-sizing: border-box; height: 310px; width: 50%; color: #fff; background-color: rgba(49, 25, 0, .9); padding: 50px 20px 0;
					@media ( max-width : 768px ) {
						margin-top: 32%; width: 100%; height: 65%; padding: 10px 20px 0;
					}
					@media ( max-width : 480px ) {
						margin-top: 0; background-color: rgba(49, 25, 0, .8);
					}
					h3{
						@include fontsize(22); color: #fff; margin: 0 0 70px;
						&:before,&:after{
							display: none;
						}
						@media ( max-width : 768px ) {
							margin-bottom: 16px; padding:0;
						}
					}
					p{
						margin-bottom: 20px;
						@media ( max-width : 768px ) {
							margin: 40px 0 20px; display: block;
						}
						&.more{
							bottom: 0; border: 1px solid #fff;
							@media ( max-width : 768px ) {
								position: relative; padding: 0; margin-bottom: 20px; width: 95px;
							}
							a{
								color:#fff; border: none;
								@media ( max-width : 768px ) {
									@include fontsize(18);
								}
								&.add_arrow:after{border-top: 1px solid #fff; border-right: 1px solid #fff;
								}
							}
						}
						&.effect{
							&:hover{
								z-index: 1;
							}
						}
					}
				}
			}
		}
	}
}

.p_ttl{
	position: relative;
	p{
		position: absolute;
		color: #fff;
		@include fontsize(28);
		@media ( max-width : 480px ) {
			@include fontsize(20);
		} // @media
		font-family: $HiraMin;
		top:50%;
		transform: translateY(-50%);
		text-align: center;
		width: 100%;
		padding: .5em 0;
		background:-moz-linear-gradient(to left, rgba(255, 255, 255, .0) 0%, rgba(255, 255, 255, .0) 10%, rgba(49, 25, 0, .85) 30%, rgba(49, 25, 0, .85) 70%, rgba(255, 255, 255, .0) 90%, rgba(255, 255, 255, .0) 100% );
		background:-ms-linear-gradient(to left, rgba(255, 255, 255, .0) 0%, rgba(255, 255, 255, .0) 10%, rgba(49, 25, 0, .85) 25%, rgba(49, 25, 0, .85) 75%, rgba(255, 255, 255, .0) 90%, rgba(255, 255, 255, .0) 100% );
		background:-webkit-linear-gradient(to left, rgba(255, 255, 255, .0) 0%, rgba(255, 255, 255, .0) 10%, rgba(49, 25, 0, .85) 30%, rgba(49, 25, 0, .85) 70%, rgba(255, 255, 255, .0) 90%, rgba(255, 255, 255, .0) 100% );
		background:linear-gradient(to left, rgba(255, 255, 255, .0) 0%, rgba(255, 255, 255, .0) 10%, rgba(49, 25, 0, .85) 25%, rgba(49, 25, 0, .85) 75%, rgba(255, 255, 255, .0) 90%, rgba(255, 255, 255, .0) 100% );
	}

}
