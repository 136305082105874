@charset "utf-8";

// ======== key color
  $color_primary: #311900; // 基本カラー

// ======== background color
  $color_body_bg: #f8f2eb; // body背景
  $color_highlight_bg: rgba($color_primary, .05); // 帯の背景
  $color_inquiry_bg: $color_highlight_bg; // お問い合わせフォーム背景

// ======== text color
  $color_text: #1b1b1b; // 基本文字色
  $color_link: $color_text; // リンクカラー
  $color_link_hover: $color_link; // リンクカラー（マウスホバー）

  $color_h1_text: #fff; // 下層h1テキスト色
  $color_h2_text: $color_primary; // h2テキスト色
  $color_h2_border: $color_primary; // h2ボーダー色
  $color_h3_text: #311900; // h3テキスト色
  $color_h4_text: $color_text; // h4テキスト色

  // 帯のテキスト色
  $color_highlight_text: $color_text; // 帯の文字色
  $color_highlight_link: $color_text; // 帯のリンクカラー
  $color_highlight_link_hover: $color_link_hover; // 帯のリンクカラー（マウスホバー）

  $color_highlight_h2_text: $color_h2_text; // 帯のh2テキスト色
  $color_highlight_h2_border: $color_h2_border; // 帯のh2ボーダー色
  $color_highlight_h3_text: $color_h3_text; // 帯のh3テキスト色

  // 固定文字色
  $color_white: #ffffff;
  $color_black: #000000;
  $color_red: #ff0000;

// ======== header

  // 共通
  $header_border_width: 0px; // ヘッダーのボーダー幅
  $header_border_position: top; // ヘッダーのボーダー位置
  $header_border_color: $color_primary; // ヘッダーのボーダー色

  // メインヘッダー
    $color_header_bg: #ffffff; // ヘッダー背景色
    $color_header_text: $color_text; // ヘッダー文字色

    $color_hamburger: $color_primary; // SPメニューボタンの色
    $color_navlink_pc: #fff; // PC Gナビリンクカラー
    $color_navlink_pc_hover: $color_primary; // PC Gナビリンクカラー(ホバー)

    $color_gnav_bg: rgba($color_primary, .7); // SP Gナビ背景色
    $color_gnav_border_sp: #333; // SP Gナビボーダーカラー
    $color_navlink_sp: #fff; // SP Gナビリンクカラー
    $color_navlink_sp_active: #fff; // SP Gナビリンクカラー（アクティブ）
    $color_navlink_sp_active_bg: $color_primary; // SP Gナビリンク背景色（アクティブ）

  // サブヘッダー
    $color_subheader_bg: $color_highlight_bg; // 背景色
    $color_subheader_text: $color_text; // 文字色

    // ローカルナビ
    $color_localnavlink: $color_text; // リンク色
    $color_localnavlink_hover: $color_primary; // リンク色(ホバー)

// ======== footer
  $color_footer_bg: $color_primary; // フッター背景色
  $color_footer_text: #ffffff; // フッターテキスト色

  $color_ftnavlink: $color_footer_text; // フッターリンク色
  $color_ftnavlink_hover: $color_ftnavlink; // フッターリンク色（ホバー）
  $color_ftnav_border_sp: #666; // フッターリンク間のボーダー(SP)

  $color_pagetop_bg: rgba(#789a30, .8); // トップに戻るボタンの背景色
  $color_pagetop_arrow: #fff; // トップに戻るボタンの矢印の色

// ======== table
  $color_table_border: $color_primary; // テーブルのボーダー色
  $color_table_th_bg: rgba($color_table_border, .7); // thの背景色
  $color_table_th_text: #fff; // thの背景色
  $color_table_td_bg: transparent; // tdの背景色

  // メニュー表
  $color_menutable_border: $color_primary; // メニュー表のボーダー色

  // 診療時間
  $color_schedule_border: $color_primary; // ボーダー色
  $color_schedule_thead_bg: rgba($color_table_border, .7); // thead > th の背景色
  $color_schedule_thead_text: #fff; // thの背景色
  $color_schedule_tbody_th_bg: transparent; // thead > th の背景色
  $color_schedule_td_bg: transparent; // td の背景色

  // effect
  $effect_arrow_color: $color_primary;
  $effect_more_color: $color_primary;
  $effect_more_color: $color_primary;

// ======== button

  $style_button_border_radius: 4px; // 角丸

  // デフォルトボタン
  $color_button_bg: $color_primary; // 背景色
  $color_button_text: #ffffff; // テキスト色
  $color_button_bg_hover: darken($color_button_bg, 10%); // ホバー時背景色
  $color_button_text_hover: $color_button_text; // ホバー時テキスト色

  // カラー反転ボタン
  $color_button_inverse_bg: #000000; // 背景色
  $color_button_inverse_text: #ffffff; // テキスト色
  $color_button_inverse_bg_hover: lighten($color_button_inverse_bg, 10%); // ホバー時背景色
  $color_button_inverse_text_hover: $color_button_inverse_text; // ホバー時テキスト色

  // キャンセルボタン
  $color_button_disable_bg: #888; // 背景色
  $color_button_disable_text: #fff; // テキスト色
  $color_button_disable_bg_hover: darken($color_button_disable_bg, 10%); // ホバー時背景色

// ======== slider
  $slide_type: 'A'; // スライドのタイプ
  $color_slider_button: #d2d2d2; // ページャーの色
  $color_slider_button_active: $color_primary; // ページャーの色（アクティブ）
  $color_slider_layer: $color_text; // キャプションの文字色
  $color_slider_shadow: #fff; // キャプションのシャドー色

  // Type A
  $shape_slider_arrow: 'square'; // 前後切り替えボタンの形状 'square' or 'circle'
  $color_sliderA_arrow: rgba($color_primary, 1 - 30/100); // 前後切り替えボタンの背景色

  // Type B
  $color_slider_mask_bg: rgba(#fff, 0.2); // マスクの背景色
  $color_sliderB_arrow: #fff; // 前後切り替えボタンの矢印の色

  // コンテンツスライダー
  $color_contents_slider_arrow: $color_primary; // 前後切り替えボタンの色

// ======== information
  $color_news_header: $color_h2_text; // お知らせのh2テキスト色
  $color_news_bg: $color_highlight_bg; // お知らせの背景色

// ======== step
  $color_step_bg: $color_highlight_bg; // STEPパーツの背景色

// ======== Q&A
  $color_qa_bg: $color_highlight_bg; // Q&Aパーツの背景色
